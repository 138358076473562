import { Grid, Box, Typography } from '@mui/material';

interface UserNotiItemProps {
  // subtitle: string,
  // icon: JSX.Element,
  message: string;
  time: string;
}

export default function UserNotiPreviewItem(props: UserNotiItemProps) {
  const { message, time } = props;

  return (
    <Box style={{ width: 300, whiteSpace: 'normal', padding: '6px 0px 6px 0px' }}>
      <Grid container>
        <Grid item container spacing={1}>
          {/* <Grid item xs={1}>
                        {icon}
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant='subtitle1'>{subtitle}</Typography>
                    </Grid> */}
          <Grid item xs={12}>
            <Typography variant='body2' color='textSecondary'>
              {time}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <Typography variant='body1'>{message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
