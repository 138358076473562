import { PWColors } from '../../../../Theme/PWColors';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getRadarStyle, radarTypes, selectRadarStyle } from '../../../../features/map/mapSlice';
import { Box, styled, ToggleButton, ToggleButtonGroup, Typography, Zoom } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { getSelectedLocation } from '../../../../features/dash/dashSlice';
import { Location } from '../../../../Services/API';

const StyledToggleButton = styled(ToggleButton)(() => ({
  border: '1px',
  borderRadius: '8px',
  padding: 8,
  '&:hover': {
    backgroundColor: '#E9E9E9',
  },
}));

export type RadarTypes = "past" | "future" | "extended";

interface RadarTypeSelectProps {
  onClick?: React.MouseEventHandler;
  show: boolean;
  handleRadarTypeChange: (newRadarType: radarTypes) => void;
  location?: Location;
  radarType: RadarTypes;
}

export function RadarTypeSelect(props: RadarTypeSelectProps) {
  const { onClick, show, location, handleRadarTypeChange, radarType } = props;

  const isInternational = location && (location?.latitude > 49 || location?.latitude < 24.5 || location?.longitude < -126 || location?.longitude > -67);

  useEffect(() => {
    if (isInternational) handleRadarTypeChange('past');
  }, [location]);

  const handleRadarChange = (event: React.MouseEvent<HTMLElement>, newRadarStyle: radarTypes) => {
    if (newRadarStyle !== null) handleRadarTypeChange(newRadarStyle);
  };

  return (
    <div style={{ position: 'absolute', marginLeft: -15, bottom: 50 }}>
      <div onClick={onClick}>
        <Box
          style={{ backgroundColor: '#fff' }}
          className='radarSelectBox'
          minWidth={75}
          border={'1px solid'}
          borderRadius={2}
          zIndex={1}>
          <Typography>{radarType}</Typography>
        </Box>
        <Box>
          <Zoom in={show}>
            <div className='overlayMenu left'>
              <ToggleButtonGroup
                color='secondary'
                exclusive
                orientation='vertical'
                value={radarType as string}
                onChange={handleRadarChange}>
                <StyledToggleButton value='past'>
                  <Typography variant='body2' color={PWColors.dark.base['900']}>
                    Past
                  </Typography>
                  <KeyboardArrowLeft color='secondary' />
                </StyledToggleButton>
                {!isInternational &&
                  <StyledToggleButton value='future'>
                    <Typography variant='body2' color={PWColors.dark.base['900']}>
                      Future
                    </Typography>
                    <KeyboardArrowRight color='secondary' />
                  </StyledToggleButton>
                }
                {!isInternational &&
                  <StyledToggleButton value='extended'>
                    <Typography variant='body2' color={PWColors.dark.base['900']}>
                      Extended
                    </Typography>
                    {/* <KeyboardArrowRight color="secondary"/> */}
                    <KeyboardDoubleArrowRight color='secondary' />
                  </StyledToggleButton>
                }
              </ToggleButtonGroup>
            </div>
          </Zoom>
        </Box>
      </div>
    </div>
  );
}

export default RadarTypeSelect;
