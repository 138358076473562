/* eslint-disable no-mixed-spaces-and-tabs */
import Oidc from 'oidc-client';
import { UserManager } from 'oidc-react';
// var post_logout_redirect_uri: string = 'http://localhost:3000/'
// var redirect_uri: string = 'http://localhost:3000/callback'
// var silent_redirect_uri: string = 'http://localhost:3000/silentrenew'
// if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SILENTRENEW_URI !== undefined) {
//     silent_redirect_uri = process.env.REACT_APP_SILENTRENEW_URI!;
// }
// if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_POSTLOGOUT_URI !== undefined) {
//     post_logout_redirect_uri = process.env.REACT_APP_POSTLOGOUT_URI!;
// }
// if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_CALLBACK_URI !== undefined) {
//     redirect_uri = process.env.REACT_APP_CALLBACK_URI!;
// }
// console.debug(window.location.origin );
export const PWUserManager = new UserManager({
  // monitorSession: false,
  automaticSilentRenew: true, // needs to be true and handle automatic silent renew as well.
  // authority: 'http://localhost:5000',
  authority: process.env.REACT_APP_LOGIN_URL,
  scope: 'openid profile api1 email phone offline_access app_access widget-api WIDGET_ADMIN',
  client_id: 'pw3-a',
  response_type: 'code',
  loadUserInfo: true,
  filterProtocolClaims: false,
  silent_redirect_uri: window.location.origin + '/silentrenew',
  // process.env.NODE_ENV === 'development'
  //     ?'http://localhost:3000/silentrenew'
  //     :'https://app.perryweather.com/silentrenew',
  post_logout_redirect_uri: window.location.origin + '/',
  // process.env.NODE_ENV === 'development'
  //   	? 'http://localhost:3000/'
  //   	: 'https://app.perryweather.com/',
  redirect_uri: window.location.origin + '/callback',
  // process.env.NODE_ENV === 'development'
  //     ? 'http://localhost:3000/callback'
  //     : 'https://app.perryweather.com/callback'
});

// Oidc.Log.logger = console;

// export const oidcConfig = {
// 	autoSignIn:false,
// 	onSignIn: async (user: any) => {
// 	  console.log('You just signed in, congratz! Check out the console!');
// 	  console.log(user);
// 	  //// Can fetch session storgae here, now how do we save it before hand.
// 	//   let redirect: string | null = sessionStorage.getItem("auth:redirect");
// 	//   if (redirect)  {
// 	// 	console.log(redirect);
// 	// 	window.location.href = redirect;
// 	//   }
// 		// window.location.href = '/';
// 	//   window.location.href = '/callback';
// 	},
// 	authority: 'https://login.pocketperry.com',
// 	scope: 'openid profile api1',
// 	clientId:
// 	  'pw3-a',
// 	responseType: 'code',
// 	postLogoutRedirectUri:
// 	  process.env.NODE_ENV === 'development'
// 		? 'http://localhost:3000/'
// 		: 'https://preview.perryweather.com/'
// 	,
// 	redirectUri:
// 	  process.env.NODE_ENV === 'development'
// 		? 'http://localhost:3000/callback'
// 		: 'https://preview.perryweather.com/callback'
//   };
