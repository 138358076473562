import { Grid, Typography, useTheme, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {
  useSitesQuery,
  Site,
  useUpdateSiteMutation,
  useAddSiteMutation,
  useDeleteSiteMutation,
  SiteAlertTime,
  SiteErrorModel,
  useUserSiteQuery,
  ModelStateErrorResponse,
} from '../../Services/API';
import {
  BaseModal,
  ModalState,
  SubFormContainer,
  TextField,
  Button,
  Link,
  Tooltip,
  DeletionDialog,
  Select,
  Switch,
  SelectOption,
} from '../Base';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../features/toast/toastSlice';
import { DaysOfWeek } from '../../features/Time/TimeConstant';
import { useAuth } from 'oidc-react';
import { useCustomerWidgetsQuery } from '../../Services/API/widgetApi';

interface GroupModalProps {
  modalState: ModalState;
}

function GenerateOptions() {
  let options: SelectOption[] = [];

  options.push({ text: 'ALL DAY', value: 'allday', bold: true });

  for (let i = 0; 24 > i; i++) {
    let twelveHourValue = ((i + 11) % 12) + 1;
    let suffix = i > 11 ? 'PM' : 'AM';
    let prefix = i < 10 ? '0' + i : i;
    options.push({ text: twelveHourValue + ':00 ' + suffix, value: prefix + ':00:00' });
    // options.push({text: (twelveHourValue + ':15 ' + suffix), value: (prefix + ':15:00')})
    options.push({ text: twelveHourValue + ':30 ' + suffix, value: prefix + ':30:00' });
    // options.push({text: (twelveHourValue + ':45 ' + suffix), value: (prefix + ':45:00')})
  }

  return options;
}

type AlertTimeUpdateTypes = 'endtime' | 'starttime' | 'enabled' | 'allday';

function GroupRow(props: {
  group: Site;
  onCancel?: Function;
  onSave?: Function;
  onDelete?: Function;
  expanded?: boolean;
  onError?: Function;
  onChange?: (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
}) {
  const { group, onCancel, onSave, onDelete, expanded = false, onChange, onError } = props;
  const totalUsers = group.users + group.notificationUsers;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [errorState, setErrorState] = useState<SiteErrorModel>({});

  const [
    updateSite,
    {
      data: updateSiteData,
      isSuccess: isUpdateSiteSuccess,
      isError: isUpdateSiteError,
      error: updateSiteError,
      isLoading: updateSiteLoading,
    },
  ] = useUpdateSiteMutation();
  const [
    createSite,
    {
      data: createSiteData,
      isSuccess: isCreateSiteSuccess,
      isError: isCreateSiteError,
      error: createSiteError,
      isLoading: createSiteLoading,
    },
  ] = useAddSiteMutation();
  const [
    deleteSite,
    { data: deleteSiteData, isSuccess: isDeleteSiteSuccess, isError: isDeleteSiteError, error: deleteSiteError },
  ] = useDeleteSiteMutation();

  const {data: customerWidgets } = useCustomerWidgetsQuery(group.customerId);

  const [siteName, setSiteName] = useState('');
  const [siteAlertTimes, setSiteAlertTimes] = useState<SiteAlertTime[]>([]);

  const timeSelectionOptions = GenerateOptions();

  const widgetsForGroup = customerWidgets?.filter(widget => widget.policyGroupId === group.id);

  useEffect(() => {
    if (group) {
      setSiteName(group.name);
      setSiteAlertTimes(group.alertTimes);
    }
  }, [group]);

  useEffect(() => {
    if (isUpdateSiteSuccess) {
      dispatch(openToast({ variant: 'success', header: 'Upated user group' }));
      if (onSave) onSave();
    } else if (isUpdateSiteError) {
      var siteModelError = (updateSiteError as ModelStateErrorResponse)?.data.responseException
        ?.errors as SiteErrorModel;
      if (siteModelError) setErrorState({ ...siteModelError });
      if (onError) onError();
      dispatch(openToast({ variant: 'error', header: 'Error updating user group' }));
    }
  }, [updateSiteData, isUpdateSiteSuccess, isUpdateSiteError, updateSiteError]);

  useEffect(() => {
    if (isCreateSiteSuccess) {
      dispatch(openToast({ variant: 'success', header: 'Created user group' }));
      if (onSave) onSave();
    } else if (isCreateSiteError) {
      var siteModelError = (createSiteError as ModelStateErrorResponse)?.data.responseException
        ?.errors as SiteErrorModel;
      if (siteModelError) setErrorState({ ...siteModelError });
      if (onError) onError();
      dispatch(openToast({ variant: 'error', header: 'Error creating user group' }));
    }
  }, [createSiteData, isCreateSiteSuccess, isCreateSiteError, createSiteError]);

  useEffect(() => {
    if (isDeleteSiteSuccess) {
      dispatch(openToast({ variant: 'success', header: 'Deleted user group' }));
      if (onDelete) onDelete();
    } else if (isDeleteSiteError) {
      if (onError) onError();
      dispatch(openToast({ variant: 'error', header: 'Error deleting user group' }));
    }
  }, [deleteSiteData, isDeleteSiteSuccess, isDeleteSiteError, deleteSiteError]);

  const updateNotificationAlertTimes = (type: AlertTimeUpdateTypes, day: number, value: string | boolean) => {
    let newNotificationAlertTimes: SiteAlertTime[] = [];
    siteAlertTimes.forEach(val => newNotificationAlertTimes.push(Object.assign({}, val)));
    let alert = siteAlertTimes.find(a => a.day === day);

    if (alert !== undefined) {
      if (value === 'allday') {
        newNotificationAlertTimes[siteAlertTimes.indexOf(alert)].allDay = true;
      } else {
        switch (type) {
          case 'endtime':
            newNotificationAlertTimes[siteAlertTimes.indexOf(alert)].allDay = false;
            newNotificationAlertTimes[siteAlertTimes.indexOf(alert)].endTime = value as string;
            break;
          case 'starttime':
            newNotificationAlertTimes[siteAlertTimes.indexOf(alert)].allDay = false;
            newNotificationAlertTimes[siteAlertTimes.indexOf(alert)].startTime = value as string;
            break;
          case 'enabled':
            newNotificationAlertTimes[siteAlertTimes.indexOf(alert)].enabled = value as boolean;
            break;
        }
      }
    }
    setSiteAlertTimes(newNotificationAlertTimes);
  };

  const handleSave = () => {
    if (group.id === 0) createSite({ ...group, name: siteName, alertTimes: siteAlertTimes });
    else updateSite({ ...group, name: siteName, alertTimes: siteAlertTimes });
  };

  const handleCancel = () => {
    setSiteAlertTimes(group.alertTimes);
    setSiteName(group.name);

    if (onCancel) onCancel();
  };

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container>
          <Grid item xs={12} sm={6} display='flex' flexDirection='column' justifyContent='center'>
            <Typography overflow='hidden' textOverflow='ellipsis' variant='h6'>
              {!expanded ? siteName : `Editing "${siteName}"`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} display='flex' flexDirection='column' justifyContent='center'>
            <Box display='flex' justifyContent='flex-start'>
              <Typography variant='body1' color='textSecondary'>
                {`${group.users + group.notificationUsers} User${totalUsers === 1 ? '' : 's'}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <hr style={{ margin: '12px 0' }} />
          <Grid item xs={12} sm={6} display='flex' flexDirection='column' justifyContent='center'>
            <Typography variant='body1' marginBottom='12px'>
              Group Name
            </Typography>
            <TextField
              helperText={errorState && errorState.Name ? errorState.Name[0] : undefined}
              isError={errorState && errorState.Name && errorState.Name.length > 0}
              value={siteName}
              onChange={e => setSiteName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <hr style={{ margin: '12px 0' }} />
            <Typography variant='body1' marginBottom='12px'>
              Alert Times
            </Typography>
            {siteAlertTimes.map(alertTime => {
              return (
                <div key={alertTime.id?.toString() || '' + alertTime.day.toString()}>
                  {alertTime.day !== 0 && <hr style={{ margin: '12px 0' }} />}
                  <Grid container>
                    <Grid item xs={10} style={{ textAlign: 'left', marginBottom: 8 }}>
                      <Typography variant='subtitle1'>{DaysOfWeek[alertTime.day]}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={false}
                      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Box display={{ sm: 'block', md: 'none' }}>
                        <Switch
                          checked={alertTime.enabled}
                          onChange={e => updateNotificationAlertTimes('enabled', alertTime.day, e.target.checked)}
                        />
                      </Box>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      {alertTime.allDay ? (
                        <Grid item md={4} xs={5}>
                          <Select
                            options={timeSelectionOptions}
                            value={alertTime.allDay ? 'allday' : alertTime.startTime}
                            disabled={!alertTime.enabled}
                            onChange={e => updateNotificationAlertTimes('starttime', alertTime.day, e)}
                          />
                        </Grid>
                      ) : (
                        <>
                          <Grid item md={4} xs={5}>
                            <Select
                              isError={
                                errorState
                                  ? (errorState as any)[`AlertTimes[${alertTime.day}].StartTime`]?.length > 0
                                  : undefined
                              }
                              options={timeSelectionOptions}
                              value={alertTime.allDay ? 'allday' : alertTime.startTime}
                              disabled={!alertTime.enabled}
                              onChange={e => updateNotificationAlertTimes('starttime', alertTime.day, e)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <span style={{ display: 'flex', justifyContent: 'center' }}>&#8211;</span>
                          </Grid>
                          <Grid item md={4} xs={5}>
                            <Select
                              isError={
                                errorState
                                  ? (errorState as any)[`AlertTimes[${alertTime.day}].StartTime`]?.length > 0
                                  : undefined
                              }
                              options={timeSelectionOptions}
                              value={alertTime.allDay ? 'allday' : alertTime.endTime}
                              disabled={!alertTime.enabled}
                              onChange={e => updateNotificationAlertTimes('endtime', alertTime.day, e)}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid
                        item
                        sm={false}
                        md={2}
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box display={{ xs: 'none', md: 'block' }}>
                          <Switch
                            checked={alertTime.enabled}
                            onChange={e => updateNotificationAlertTimes('enabled', alertTime.day, e.target.checked)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='caption' color={theme.palette.error.light}>
                          {errorState ? (errorState as any)[`AlertTimes[${alertTime.day}].StartTime`] : undefined}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Grid>
          <Grid marginTop='16px' display='flex' justifyContent='flex-end' item xs={12} color={theme.palette.error.main}>
            <Button
              disabled={createSiteLoading || updateSiteLoading}
              type='primary'
              style={{ marginRight: '12px' }}
              onClick={handleSave}>
              Save
            </Button>
            <Button type='clear' style={{ marginRight: '12px' }} onClick={handleCancel}>
              Cancel
            </Button>
            {group.policies > 0 || (widgetsForGroup && widgetsForGroup.length > 0) ? (
              <div>
                <DeletionDialog
                  button={
                    <Button disabled={totalUsers > 0} type='delete'>
                      Delete
                    </Button>
                  }
                  tooltip={{
                    title: 'Remove all users in group before deleting the group.',
                    disableHoverListener: totalUsers < 1,
                    disableTouchListener: totalUsers < 1,
                  }}
                  disabled={totalUsers > 0}
                  item={group.name}
                  onRemove={e => deleteSite(group.id)}>
                  <>
                    {group.policies > 0 &&
                      <Typography variant='body1' marginBottom={2}>
                        This group is part of {group.policies} {group.policies === 1 ? 'policy' : 'policies'}. If it is the
                        only group in a policy, it could result in certain alerts not being sent. 
                      </Typography>
                    }
                    {widgetsForGroup && widgetsForGroup.length > 0 &&
                    <>
                      <Typography variant='body1'>
                        This group controls the following widget{widgetsForGroup.length > 1 ? "s: ": ": "} 
                        {widgetsForGroup.map((widget, index) => { 
                          return (
                            <Typography component="span" color={theme.palette.info.main} key={widget.id}>
                              {widget.name}{index < widgetsForGroup.length - 1 ? ', ' : ''}
                            </Typography> 
                          )
                        })}
                      </Typography>

                      <Typography variant='body1' marginBottom={2}>
                        Removing this group will cause {widgetsForGroup.length > 1 ? "these widgets" : "this widget"} to stop working.
                      </Typography>
                    </>
                    }
                    <Typography variant='body1'>
                      Are you sure you want to delete this group?
                    </Typography>
                  </>
                </DeletionDialog>
              </div>
            ) : (
              <Tooltip
                followCursor
                disableTouchListener={totalUsers < 1}
                disableHoverListener={totalUsers < 1}
                title='Remove all users in group before deleting the group.'>
                <div>
                  <Button
                    onClick={e => {
                      deleteSite(group.id);
                    }}
                    type='delete'
                    disabled>
                    Delete
                  </Button>
                </div>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export function GroupsModal(props: GroupModalProps) {
  const { modalState } = props;
  const [newGroup, setNewGroup] = useState(false);

  const { userData: user } = useAuth();

  const defaultAlertTimes: SiteAlertTime[] = [
    {
      id: null,
      day: 0,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
    {
      id: null,
      day: 1,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
    {
      id: null,
      day: 2,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
    {
      id: null,
      day: 3,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
    {
      id: null,
      day: 4,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
    {
      id: null,
      day: 5,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
    {
      id: null,
      day: 6,
      startTime: '05:00:00',
      endTime: '23:00:00',
      allDay: false,
      enabled: true,
    },
  ];

  const { data: customerGroups } = useSitesQuery(undefined, {
    skip: !(user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin'),
  });
  const { data: userGroup } = useUserSiteQuery(undefined, { skip: user?.profile?.Role !== 'Assistant Admin' });

  const modalRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [userGroups, setUserGroups] = useState<Site[]>();

  const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = () => {
    // if(user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin')
    //     refetchCustomerGroups();
    // else if(user?.profile?.Role === 'Assistant Admin')
    //     refetchUserGroup();
  };

  const handleSave = () => {
    setExpanded(false);
    handleDelete();
  };

  useEffect(() => {
    if (customerGroups) setUserGroups(customerGroups);
    else if (userGroup) setUserGroups([userGroup]);
  }, [customerGroups, userGroup]);

  useEffect(() => {
    if (userGroups) {
      setNewGroup(false);
    }
  }, [userGroups]);

  return (
    <BaseModal
      ref={modalRef}
      modalState={{
        ...modalState,
        handleClose: () => {
          modalState.handleClose();
          setNewGroup(false);
        },
      }}>
      <Typography marginBottom='8px' variant='h5'>
        {' '}
        Manage User Groups{' '}
      </Typography>
      <SubFormContainer containerDesc='Organize and manage your users in User Groups by assigning the groups Limited Admins and Policies.'>
        {userGroups?.map(group => (
          <GroupRow
            onError={() => {
              modalRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            expanded={expanded === 'group' + group.id.toString()}
            onChange={handleExpand('group' + group.id.toString())}
            key={group.id}
            onDelete={handleDelete}
            onCancel={() => setExpanded(false)}
            onSave={handleSave}
            group={group}
          />
        ))}
        {newGroup && (
          <GroupRow
            onError={() => {
              modalRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            onChange={handleExpand('newGroup')}
            expanded={expanded === 'newGroup'}
            onDelete={handleDelete}
            onSave={handleSave}
            onCancel={() => setNewGroup(false)}
            group={{
              id: 0,
              name: 'New Group',
              customerId: 0,
              users: 0,
              notificationUsers: 0,
              policies: 0,
              alertTimes: defaultAlertTimes,
            }}
          />
        )}
        {(user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin') && (
          <Box display='flex' marginTop='16px'>
            <Link
              onClick={e => {
                setNewGroup(true);
                setExpanded('newGroup');
              }}>
              <Typography> + New group...</Typography>
            </Link>
          </Box>
        )}
      </SubFormContainer>
      <Box marginTop='16px' display='flex' justifyContent='flex-end'>
        <Button
          type='clear'
          onClick={e => {
            setNewGroup(false);
            modalState.handleClose();
          }}>
          Close
        </Button>
      </Box>
    </BaseModal>
  );
}
