import { api } from '.';

export interface SupportDto {
  email: string;
  phone: string;
  preferredContact?: string;
  message: string;
  ccdEmails?: string[];
}

export interface WidgetInstructionsDto {
  recipient: string;
  sender: string;
  widgetId: string;
}

export interface DisableUnit {
  message: string;
  hardwareId: number;
}

const supportApi = api.injectEndpoints({
  endpoints: builder => ({
    submitSupportRequest: builder.mutation<string, SupportDto>({
      query: request => ({
        url: 'Support',
        method: 'POST',
        body: request,
      }),
      transformResponse: (response: { data: string }) => response.data,
    }),
    disableUnit: builder.mutation<string, DisableUnit>({
      query: request => ({
        url: 'Support/Disable',
        method: 'POST',
        body: request,
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['Hardware'],
    }),
    enableUnit: builder.mutation<string, DisableUnit>({
      query: request => ({
        url: 'Support/Enable',
        method: 'POST',
        body: request,
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['Hardware'],
    }),
    sendWidgetInstructions: builder.mutation<string, WidgetInstructionsDto>({
      query: request => ({
        url: 'Support/Widget',
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const {
  useSubmitSupportRequestMutation,
  useEnableUnitMutation,
  useDisableUnitMutation,
  useSendWidgetInstructionsMutation,
} = supportApi;
