import { api } from '.';

export interface Customer {
  id: number;
  name: string;
  numberOfUsers: number;
  numberOfNotiOnlyUsers: number;
  numberOfWidgets: number;
  locationLimit?: number;
  latitude?: number;
  longitude?: number;
  statusId: number;
  status: string;
  externalId: string;
  notes: string;
  entitlements: CustomerEntitlements[];
  liveStreamMinutes: number | undefined;
}

export enum CustomerEntitlements {
  GPS_Tracking = 1,
  MET_SUPPORT = 2,
  PA_FULL_ACCESS = 3,
  AQI_ACCESS = 4,
  LEGACY_ACCESS = 5,
  MASS_NOTIFY_ACCESS = 6,
  WIDGET_ACCESS = 7,
  LIVE_CAMERA_ACCESS = 8,
  FOURTH_LIGHTNING_ZONE_SUPPORT = 9,
}


const customerApi = api.injectEndpoints({
  endpoints: builder => ({
    customers: builder.query<Customer[], void>({
      query: id => ({ url: `Customer` }),
      transformResponse: (response: { data: Customer[] }) => response.data,
    }),
    customer: builder.query<Customer, number>({
      query: id => ({ url: `Customer/${id}` }),
      transformResponse: (response: { data: Customer }) => response.data,
    }),
  }),
});

export const { useCustomersQuery, useCustomerQuery, useLazyCustomerQuery } = customerApi;
