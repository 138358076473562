import { Close } from '@mui/icons-material';
import { Grid, Typography, Paper, PaperProps, Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { memo } from 'react';
import { closeToast, selectToast } from '../../features/toast/toastSlice';
import { useAppDispatch } from '../../app/hooks';

export type BreadVariant = 'error' | 'warn' | 'info' | 'success';

interface StyledPaperProps extends PaperProps {
  breadvariant: BreadVariant;
}

interface BreadProps {
  variant: BreadVariant;
  header?: string;
  icon?: JSX.Element;
  message?: string;
  closeable?: boolean;
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: prop => prop !== 'success',
})<StyledPaperProps>(({ breadvariant, theme }) => ({
  width: '100%',
  padding: '8px 16px 8px 16px',
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  ...(breadvariant === 'error' && {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.background.error,
  }),
  ...(breadvariant === 'warn' && {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.background.warning,
  }),
  ...(breadvariant === 'info' && {
    color: theme.palette.info.light,
    borderColor: theme.palette.info.light,
    backgroundColor: theme.palette.info.dark,
  }),
  ...(breadvariant === 'success' && {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.background.success,
  }),
}));

export const Bread = memo(function Bread(props: BreadProps) {
  const { variant, header, message, icon, closeable } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  return (
    <StyledPaper breadvariant={variant} theme={theme} sx={{ display: 'flex', alignItems: 'center' }}>
      {header ? (
        <>
          <Grid container spacing={1}>
            {icon && (
              <Grid
                item
                xs={2}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: 48 }}>
                {icon}
              </Grid>
            )}
            <Grid item xs>
              <Typography textAlign='left' variant='subtitle1'>
                {header}
              </Typography>
            </Grid>
          </Grid>
          {message && (
            <Grid container>
              {icon && <Grid item xs={2} />}
              <Grid item xs>
                <Typography textAlign='left' variant='body2'>
                  {message}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid container spacing={1}>
          {icon && (
            <Grid
              item
              xs={2}
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: 48 }}>
              {icon}
            </Grid>
          )}
          <Grid item xs style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography textAlign='left' variant='body2'>
              {message}
            </Typography>
          </Grid>
        </Grid>
      )}
      {closeable && (
        <Grid item>
          <Button color='inherit' onClick={() => dispatch(closeToast())}>
            <Close />
          </Button>
        </Grid>
      )}
    </StyledPaper>
  );
});
