/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from '../../Base/Card';
import ReactMapGL, { FullscreenControl, MapRef, AttributionControl } from 'react-map-gl';
import './map.css';

import { getBaseLayer, getActionType, getOverlays } from '../../../features/map/mapSlice';

import { useAppSelector } from '../../../app/hooks';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import { LngLat } from 'maplibre-gl';
import { store } from '../../../app/store';
import { NWSMapAlert, Observation, useAlertsByPointMutation, useObservationsMutation, useUserLocationsQuery, useUserOrgLocationsQuery } from '../../../Services/API';
import {
    NWSAlertLayer,
    StormVectorLayer,
    SurfaceAnalysisLayer,
    TropicalModelLayer,
} from './Layers';
import { NWSAlertView, ObservationView, StormVectorView, StrikeView } from './Views';
import { LocationMarker } from './Markers';
import {
    BaseLayerControl,
    BottomLayerGroup,
    CenterControl,
    LayerControl,
    LeftLayerGroup,
    LegendControl,
    ObservationsControl,
    ZoomControl,
    RightLayerGroup,
    MeasureControl,
} from './Controls';
import { MapEditor } from './MapEditor';
import { useMediaQuery, useTheme } from '@mui/material';
import LightningQuickLayer from './Layers/LightningQuickLayer';
import TropicalForecastLayer from './Layers/TropicalForecastLayer';
import TropicalForecastView from './Views/TropicalForecastView';
import { Radar } from './Radar/Radar';
import RadarOptionsPortal from './Radar/RadarOptionsPortal';
import MasterMapControl from './Controls/MasterMapControl';
import MasterMapLocationMarkers from './Markers/MasterMapLocationMarkers';
import { useAuth } from 'oidc-react';
import MasterMapLocation from '../../../types/MasterMapLocation';

const fsControlStyle = {
    left: 10,
    top: 10,
};

const groupControlStyle = {
    // left: 10,
    zIndex: 100,
    // bottom: 40,
    bottom: 20,
};

type baseLayerType = 'satellite' | 'dark' | 'light';

const mapTilerKey = process.env.REACT_APP_MAP_TILER_KEY;
const getMapStyle = (type: baseLayerType) => {
    switch (type) {
        case 'satellite':
            return `https://api.maptiler.com/maps/3cd87d39-7b76-49d8-ac14-7320e18cc301/style.json?key=${mapTilerKey}`;
        case 'dark':
            return `https://api.maptiler.com/maps/7a330e3e-5974-4112-9b7f-15f3a2416735/style.json?key=${mapTilerKey}`;
        case 'light':
            return `https://api.maptiler.com/maps/basic/style.json?key=${mapTilerKey}`;
    }
};

interface MapProps {
    children?: React.ReactNode;
}

type ActiveElementType = 'none' | 'options' | 'radar' | 'layer' | 'base' | 'legend';

export default function Map(props: MapProps) {
    const { children } = props;
    const { userData: user } = useAuth();
    const theme = useTheme();
    const [getAlertsByPoint, { data: alertsByPoint }] = useAlertsByPointMutation();

    const selectedLocation = useAppSelector(getSelectedLocation);

    const [getObservation, { data: observationData }] = useObservationsMutation();
    const [getMetarObservation, { data: metarObservationData }] = useObservationsMutation();

    const mapRef = useRef<MapRef>(null);

    const [activeElement, setActiveElement] = useState<ActiveElementType>('none');
    const [observation, setObservation] = useState<Observation | undefined>(undefined);
    const [metarObservation, setMetarObservation] = useState<Observation | undefined>(undefined);

    const [alerts, setAlerts] = useState<NWSMapAlert[]>();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { data: subscribedLocations, isLoading: subscribedLocationsLoading } = useUserLocationsQuery({ userId: user?.profile?.sub || '', isNotiOnly: false });
    const { data: organizationLocations, isLoading: organizationLocationsLoading } = useUserOrgLocationsQuery(user?.profile?.sub || '',);

    const filteredSubscribedLocations = subscribedLocations?.filter(subLoc => subLoc.latitude !== selectedLocation?.latitude && subLoc.longitude !== selectedLocation?.longitude) as MasterMapLocation[]
    const filteredOrgLocations = organizationLocations?.map(orgLoc => {
        return {
            latitude: orgLoc.latitude,
            longitude: orgLoc.longitude,
            label: orgLoc.name + `<div><i style="font-size:.8rem">Organization Location</i></div>`,
        }
    })
        // Filter out org location if it is the same as the selected location
        .filter(orgLoc => orgLoc.latitude !== selectedLocation?.latitude && orgLoc.longitude !== selectedLocation?.longitude)
        // Filter out org locations that are the same as a subscribed location
        .filter(orgLoc => orgLoc.latitude !== subscribedLocations?.find(subLoc => subLoc.latitude === orgLoc.latitude && subLoc.longitude === orgLoc.longitude)?.latitude && orgLoc.longitude !== subscribedLocations?.find(subLoc => subLoc.latitude === orgLoc.latitude && subLoc.longitude === orgLoc.longitude)?.longitude) as MasterMapLocation[]


    const [viewport, setViewport] = useState({
        latitude: 32.7767,
        longitude: -96.797,
        zoom: 8,
    });

    const [showAllLocations, setShowAllLocations] = useState<boolean>(false);

    const [clickedInfo, setClickedInfo] = useState<any>(undefined);

    const actionType = useAppSelector(getActionType);

    function onVPChange(nextVP: any) {
        setClickedInfo(null);
        setViewport(nextVP);
    }

    const resetObservation = () => {
        setObservation(undefined);
        setMetarObservation(undefined);
    };

    useEffect(() => {
        if (selectedLocation) {
            setViewport({
                latitude: selectedLocation!.latitude,
                longitude: selectedLocation!.longitude,
                zoom: 8,
            });
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (mapRef.current) {
            let map = mapRef.current!.getMap();
            if (map) {
                // map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/cat.png', (error: any, image: any) => {
                map.loadImage('/LightningStrike.png', (error: any, image: any) => {
                    if (error) throw error;
                    if (!map.hasImage('map-lightning')) map.addImage('map-lightning', image, { sdf: true });
                });
                map.loadImage('/LightningStrike-Red.png', (error: any, image: any) => {
                    if (error) throw error;
                    if (!map.hasImage('map-lightning-outline-red'))
                        map.addImage('map-lightning-outline-red', image, { sdf: false });
                });
                map.loadImage('/LightningStrike-Yellow.png', (error: any, image: any) => {
                    if (error) throw error;
                    if (!map.hasImage('map-lightning-outline-yellow'))
                        map.addImage('map-lightning-outline-yellow', image, { sdf: false });
                });
                map.loadImage('/LightningStrike-Green.png', (error: any, image: any) => {
                    if (error) throw error;
                    if (!map.hasImage('map-lightning-outline-green'))
                        map.addImage('map-lightning-outline-green', image, { sdf: false });
                });
                map.loadImage('/Triangle.png', (error: any, image: any) => {
                    if (error) throw error;
                    if (!map.hasImage('triangle-outline')) map.addImage('triangle-outline', image, { sdf: true });
                });
            }
        }
    }, [mapRef]);

    useEffect(() => {
        setAlerts(alertsByPoint);
    }, [alertsByPoint]);

    const chosenStyle = useAppSelector(getBaseLayer);
    const chosenLayers = useAppSelector(getOverlays);
    let mapStyle = getMapStyle(chosenStyle);

    const onClick = useCallback(
        event => {
            const {
                features,
            } = event;
            var offset = event.offsetCenter;
            const clickedFeature = features && features[0];
            const currentLayers = store.getState().map.overlays;

            const obsActive = store.getState().map.actionType === 'observation';

            if (currentLayers.includes('nws-alerts')) {
                getAlertsByPoint({ longitude: event.lngLat[0], latitude: event.lngLat[1] });
            }

            if (!clickedFeature && obsActive) {
                getObservation({ coord: { latitude: event.lngLat[1], longitude: event.lngLat[0] }, preferred: true });
                getMetarObservation({ coord: { latitude: event.lngLat[1], longitude: event.lngLat[0] }, preferred: false });
            } else {
                resetObservation();
            }

            setClickedInfo({
                feature: clickedFeature,
                x: offset.x,
                y: offset.y,
                lngLat: new LngLat(event.lngLat[0], event.lngLat[1]),
            });
        },
        [getAlertsByPoint]
    );

    const onChildClick = (element: ActiveElementType) => {
        if (element === activeElement) setActiveElement('none');
        else setActiveElement(element);
    };

    const getInteractiveLayerIds = () => {
        let layerIds: string[] = [];
        if (chosenLayers.includes('lightning')) {
            layerIds.push('unclustered-point-green', 'unclustered-point-yellow', 'unclustered-point-red');
        }

        if (chosenLayers.includes('storm-vectors')) {
            layerIds.push('storm-vector-point');
        }

        if (chosenLayers.includes('tropical-forecast')) {
            layerIds.push('tropical-forecast-point-layer');
        }
        return layerIds;
    };

    useEffect(() => {
        if (actionType !== 'observation') {
            resetObservation();
        }
    }, [actionType]);

    useEffect(() => {
        setObservation(observationData);
    }, [observationData]);

    useEffect(() => {
        setMetarObservation(metarObservationData);
    }, [metarObservationData]);

    const container = useRef<HTMLDivElement>(null);

    return (
        <Card fullContent header='Live Radar'>
            <ReactMapGL
                className='main-map'
                {...viewport}
                width='100%'
                height='100%' // <--- Has problems in fullscreen, using 100vh causes a much too large map <--- Groß
                // height="calc(100% - 32px)" // <--- Has problems in fullscreen, using 100vh causes a much too large map <--- Groß
                mapStyle={mapStyle}
                onViewportChange={onVPChange}
                interactiveLayerIds={getInteractiveLayerIds()}
                onClick={onClick}
                ref={mapRef}
                attributionControl={false}
                style={{ cursor: 'cross-hair' }}>
                <AttributionControl style={{ bottom: 0, right: 0, fontSize: 10 }} compact={false} />
                <LocationMarker />
                {
                    chosenLayers.includes('radar')
                    && mapRef.current
                    && mapRef.current.getMap()
                    &&
                    <Radar
                        container={container}
                        location={selectedLocation}
                        hasLocation={selectedLocation !== undefined}
                        mapRef={mapRef.current?.getMap()}
                        viewport={viewport}
                    />
                }
                {chosenLayers.includes('surface-analysis') ? <SurfaceAnalysisLayer /> : <></>}
                {chosenLayers.includes('tropical-models') ? <TropicalModelLayer /> : <></>}
                {chosenLayers.includes('tropical-forecast') ? <TropicalForecastLayer /> : <></>}

                {children}

                {actionType === 'measure' && <MapEditor />}

                {clickedInfo && actionType === 'observation' && observation && (
                    <ObservationView clickedInfo={clickedInfo} observation={observation} metarObservation={metarObservation} />
                )}
                {clickedInfo && clickedInfo.feature && clickedInfo?.feature.source === 'lightning' && (
                    <StrikeView clickedInfo={clickedInfo} />
                )}

                {clickedInfo && clickedInfo.feature && clickedInfo?.feature.source === 'tropical-forecast-points' && (
                    <TropicalForecastView clickedInfo={clickedInfo} />
                )}

                {clickedInfo && clickedInfo.feature && clickedInfo?.feature.source === 'storm-vectors' && (
                    <StormVectorView clickedInfo={clickedInfo} />
                )}

                {chosenLayers.includes('nws-alerts') && clickedInfo && alerts && alerts.length !== 0 && (
                    <NWSAlertView clickedInfo={clickedInfo} alerts={alerts} />
                )}

                {/* Subscribed locations */}
                {!subscribedLocationsLoading && showAllLocations && <MasterMapLocationMarkers locations={filteredSubscribedLocations} />}

                {/* Org locations */}
                {!organizationLocationsLoading && showAllLocations && <MasterMapLocationMarkers locations={filteredOrgLocations} />}

                <BottomLayerGroup style={groupControlStyle} className={undefined}>
                    <RadarOptionsPortal container={container} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexGrow: 0, marginRight: "10px", gap: 10 }}>
                        <LayerControl onClick={e => onChildClick('layer')} show={activeElement === 'layer'} />
                        <BaseLayerControl onClick={e => onChildClick('base')} show={activeElement === 'base'} />
                    </div>
                </BottomLayerGroup>
                {mapRef.current && chosenLayers.includes('storm-vectors') ? <StormVectorLayer /> : <></>}
                {
                    mapRef.current
                    && mapRef.current.getMap()
                    && chosenLayers.includes('lightning')
                    && (
                        <LightningQuickLayer viewport={viewport} mapRef={mapRef.current.getMap()} />
                    )
                }
                {mapRef.current && chosenLayers.includes('nws-alerts') ? (
                    <NWSAlertLayer viewport={viewport} mapRef={mapRef.current?.getMap()} />
                ) : (
                    <></>
                )}
                <LeftLayerGroup style={fsControlStyle} className={undefined}>
                    <ZoomControl />
                    <FullscreenControl style={{ position: 'relative', marginBottom: 10 }} />
                    <CenterControl />
                    {!isMobile && (
                        <>
                            <MeasureControl />
                            <ObservationsControl />
                            <MasterMapControl incrementState={() => setShowAllLocations(!showAllLocations)} isActive={showAllLocations} />
                        </>
                    )}
                </LeftLayerGroup>
                <RightLayerGroup>
                    <LegendControl onClick={e => onChildClick('legend')} show={activeElement === 'legend'} />
                    {isMobile && (
                        <>
                            <MeasureControl />
                            <ObservationsControl />
                            <MasterMapControl incrementState={() => setShowAllLocations(!showAllLocations)} isActive={showAllLocations} />
                        </>
                    )}
                </RightLayerGroup>
            </ReactMapGL>
        </Card>
    );
}
