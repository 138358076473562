import { api } from './api';

export interface AirQualityVM {
  id: string;
  name: string;
  customerId: number;
  customerName: string;
  weatherStationId: string;
  weatherStationName: string;
  lastActivity?: Date;
  pM2_5: PM2_5;
}

export interface AirQuality {
  pM2_5: PM2_5;
}

export interface PM2_5 {
  now?: number;
  '10-Minutes'?: number;
  '30-Minutes'?: number;
  '1-Hour'?: number;
  '1-Day'?: number;
  '1-Week'?: number;
}

const airQualityApi = api.injectEndpoints({
  endpoints: builder => ({
    airQualitySensors: builder.query<AirQualityVM[], void>({
      query: id => ({ url: 'AirQuality' }),
      transformResponse: (response: { data: AirQualityVM[] }) => response.data,
    }),
  }),
});

export const { useAirQualitySensorsQuery } = airQualityApi;
