import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { BreadVariant } from '../../Components/Base/Bread';

export interface ToastState {
  show: boolean;
  variant: BreadVariant;
  header: string;
  message?: string;
  autoHideDuration?: number;
}

export interface ToastContent {
  variant: BreadVariant;
  header: string;
  message?: string;
}

const initialState: ToastState = {
  show: false,
  variant: 'success',
  header: '',
  message: undefined,
  autoHideDuration: undefined,
};

const MIN_DISPLAY_TIME = 3000;

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    closeToast: state => {
      state.show = false;
    },
    openToast: (state, actions: PayloadAction<ToastContent>) => {
      state.variant = actions.payload.variant;
      state.header = actions.payload.header;
      state.message = actions.payload.message;
      state.autoHideDuration =
        actions.payload.header.length * 80 < MIN_DISPLAY_TIME ? MIN_DISPLAY_TIME : actions.payload.header.length * 80;
      state.show = true;
    },
  },
});

export const { closeToast, openToast } = toastSlice.actions;

export const selectToast = (state: RootState) => state.toast;

export default toastSlice.reducer;
