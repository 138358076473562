import { useEffect, useState } from 'react';
import { Timeline } from './Timeline';
import { PlayButton } from './PlayButton';
import { LayerProps } from 'react-map-gl';
import { Map, RasterPaint } from 'maplibre-gl';
import { Location } from '../../../../Services/API';
import { CircularProgress, Portal } from '@mui/material';
import { Viewport } from '../Util';
import OpacityAndSpeedOptions from './OpacityAndSpeedOptions';
import { radarTypes } from '../../../../features/map/mapSlice';
import RadarTypeSelect from './RadarTypeSelect';
import PlayImageSource from './Sources/PlayImageSource';
import ScrubImageSource from './Sources/ScrubImageSource';
import StaticTmsSource from './Sources/StaticTmsSource';

export enum RadarState {
  STATIC,
  SCRUB,
  PLAY,
}

export interface RadarProps {
  container: React.RefObject<HTMLDivElement>;
  hasLocation?: boolean;
  viewport: Viewport;
  mapRef: Map;
  location: Location | undefined;
}

export enum RadarSpeed {
  SLOW = 1000,
  NORMAL = 750,
  FAST = 500,
}

export function Radar(props: RadarProps) {
  const { container, mapRef, location, hasLocation, viewport } = props;
  const [radarState, setRadarState] = useState<RadarState>(RadarState.STATIC);
  const [progress, setProgress] = useState(0);
  const [labelText, setLabelText] = useState<string>('');
  const [activeElement, setActiveElement] = useState<string>('');
  const [opacity, setOpacity] = useState<number>(1);
  const [speed, setSpeed] = useState<RadarSpeed>(RadarSpeed.NORMAL);
  const [radarType, setRadarType] = useState<radarTypes>('past');
  const [radarIsLoading, setRadarIsLoading] = useState<boolean>(true);

  const handleRadarStateChange = (value: RadarState) => {
    setRadarState(value);
  };

  const handleSetProgress = (value: number) => {
    setProgress(value);
  };

  const onChildClick = (element: string) => {
    if (element === activeElement) setActiveElement('none');
    else setActiveElement(element);
  };

  const handleOpacityChange = (value: number) => {
    setOpacity(value);
  };

  const handleSpeedChange = (value: RadarSpeed) => {
    setSpeed(value);
  };

  const handleRadarTypeChange = (value: radarTypes) => {
    setRadarType(value);
  };

  const handleLabelChange = (value: string) => {
    setLabelText(value);
  };

  const handleLoadingState = (isLoading: boolean) => {
    setRadarIsLoading(isLoading);
  };

  useEffect(() => {
    if (radarState === RadarState.PLAY || radarState === RadarState.SCRUB) {
      setRadarState(RadarState.STATIC);
    }
  }, [viewport]);

  useEffect(() => {
    if (radarType === 'past') {
      setProgress(100);
    }
    if (radarType === 'future') {
      setProgress(0);
    }
    if (radarType === 'extended') {
      setProgress(0);
    }
    setRadarState(RadarState.STATIC);
  }, [radarType]);

  return (
    <>
      {radarState === RadarState.STATIC && (
        <StaticTmsSource
          hasLocation={hasLocation}
          viewport={viewport}
          mapRef={mapRef}
          opacity={opacity}
          location={location}
          radarType={radarType}
          progress={progress}
          handleLabelChange={handleLabelChange}
          handleLoadingState={handleLoadingState}
        />
      )}
      {radarState === RadarState.SCRUB && (
        <ScrubImageSource
          hasLocation={hasLocation}
          viewport={viewport}
          mapRef={mapRef}
          opacity={opacity}
          location={location}
          radarType={radarType}
          progress={progress}
          handleLabelChange={handleLabelChange}
          handleLoadingState={handleLoadingState}
        />
      )}
      {radarState === RadarState.PLAY && (
        <PlayImageSource
          hasLocation={hasLocation}
          viewport={viewport}
          mapRef={mapRef}
          opacity={opacity}
          location={location}
          radarType={radarType}
          progress={progress}
          handleSetProgress={handleSetProgress}
          speed={speed}
          handleLabelChange={handleLabelChange}
          handleLoadingState={handleLoadingState}
        />
      )}

      {radarIsLoading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}>
          <CircularProgress size={80} />
        </div>
      )}

      {/* This Portal has to render as a child of BottomLayerGroup. You can find the portal "exit" in Map.tsx, and component in RadarOptionsPortal.tsx  */}
      <Portal container={container.current}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flexGrow: 0,
            marginLeft: '10px',
            gap: 10,
          }}>
          <OpacityAndSpeedOptions
            onClick={e => onChildClick('options')}
            show={activeElement === 'options'}
            handleOpacityChange={handleOpacityChange}
            handleSpeedChange={handleSpeedChange}
            speed={speed}
          />
          <div>
            <RadarTypeSelect
              onClick={e => onChildClick('radar')}
              show={activeElement === 'radar'}
              handleRadarTypeChange={handleRadarTypeChange}
              location={location}
              radarType={radarType}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            flexGrow: 1,
            gap: 20,
            marginRight: '10px',
          }}>
          <PlayButton handleRadarStateChange={handleRadarStateChange} radarState={radarState} />
          <Timeline
            handleRadarStateChange={handleRadarStateChange}
            handleSetProgress={handleSetProgress}
            labelText={labelText}
            radarState={radarState}
            progress={progress}
          />
        </div>
      </Portal>
    </>
  );
}

const paints: RasterPaint = {
  'raster-opacity': 1,
};

const layerStyle: LayerProps = {
  id: 'radar',
  type: 'raster',
  minzoom: 0,
  maxzoom: 14,
  paint: paints,
};

const animStyle: LayerProps = {
  id: 'radar-anim',
  type: 'raster',
  minzoom: 0,
  maxzoom: 14,
  paint: {
    'raster-fade-duration': 0,
    // "raster-resampling": 'nearest',
    'raster-opacity': 1,
  },
};
