import { Check, WarningAmberRounded } from '@mui/icons-material';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../features/toast/toastSlice';
import { DisableUnit, useDisableUnitMutation, useEnableUnitMutation } from '../../Services/API';
import { BaseModal, Button, SubFormContainer, TextArea } from '../Base';

interface HardwareDisablePromptProps {
  hardwareId?: number;
  enabled: boolean;
  hardwareName: string;
}

export function HardwareDisablePrompt(props: HardwareDisablePromptProps) {
  const { hardwareId, hardwareName, enabled } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<DisableUnit>({ hardwareId: 0, message: '' });

  const [
    disableUnit,
    { data: disableData, isSuccess: isDisableSuccess, isError: isDisableError, isLoading: isDisableLoading },
  ] = useDisableUnitMutation();
  const [
    enableUnit,
    { data: enableData, isSuccess: isEnableSuccess, isError: isEnableError, isLoading: isEnableLoading },
  ] = useEnableUnitMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisable = () => {
    if (enabled) disableUnit(request);
    else enableUnit(request);
  };

  useMemo(() => {
    if (hardwareId) setRequest(r => ({ ...r, hardwareId: hardwareId }));
  }, [hardwareId]);

  useEffect(() => {
    if (isDisableSuccess) {
      setOpen(false);
      setRequest(r => ({ ...r, message: '' }));
      dispatch(openToast({ variant: 'success', header: 'Successfully disabled hardware' }));
    }
    if (isDisableError) {
      dispatch(openToast({ variant: 'error', header: 'Error disabling hardware' }));
    }
  }, [isDisableSuccess, isDisableError, disableData]);

  useEffect(() => {
    if (isEnableSuccess) {
      setOpen(false);
      setRequest(r => ({ ...r, message: '' }));
      dispatch(openToast({ variant: 'success', header: 'Successfully enabling hardware' }));
    }
    if (isEnableError) {
      dispatch(openToast({ variant: 'error', header: 'Error enabling hardware' }));
    }
  }, [isEnableSuccess, isEnableError, enableData]);

  return hardwareId ? (
    <BaseModal
      modalState={{ open: open, handleOpen: handleOpen, handleClose: handleClose }}
      modalButton={
        <Button type={enabled ? 'delete' : 'primary'}>
          <Typography variant='body1'>{enabled ? 'Disable Unit' : 'Enable Unit'}</Typography>
        </Button>
      }>
      <Grid container item style={{ marginBottom: 8 }}>
        <Grid item md={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display='flex' justifyContent='center' width='100%'>
            <Box
              component='span'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              style={{
                backgroundColor: enabled ? theme.palette.error.main : theme.palette.success.main,
                borderRadius: '50%',
                height: 40,
                width: 40,
              }}>
              <Box display='flex' justifyContent='center' width='100%'>
                {enabled ? (
                  <WarningAmberRounded fontSize='medium' />
                ) : (
                  <Check fontSize='medium' htmlColor={theme.palette.background.paper} />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={10} xs={12}>
          <Typography variant='h5'>
            {enabled ? 'Disable ' : 'Enable '} {hardwareName}?
          </Typography>
        </Grid>
      </Grid>
      <Grid container item style={{ marginBottom: 16 }}>
        <SubFormContainer>
          <Typography> {`Please let us know why you are ${enabled ? 'disabling' : 'enabling'} this unit.`}</Typography>
          <br></br>
          <TextArea
            placeholder={enabled ? 'Enter message here...' : 'Enter optional message here...'}
            value={request.message}
            sx={{ height: '150px' }}
            onChange={e => setRequest(u => ({ ...u, message: e.target.value }))}
          />
        </SubFormContainer>
      </Grid>
      <Grid container item>
        <Grid item md={6} xs={false} />
        <Grid item container spacing={1} md={6} xs={12}>
          <Grid item xs={6}>
            <Button type='clear' onClick={handleClose}>
              <Typography variant='body1'>Cancel</Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={(enabled && request.message.trim().length < 1) || isEnableLoading || isDisableLoading}
              type={enabled ? 'delete' : 'primary'}
              onClick={handleDisable}>
              <Typography variant='body1'>{enabled ? 'Disable' : 'Enable'}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  ) : (
    <></>
  );
}
