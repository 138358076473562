import React, { useMemo } from 'react';
import Page from '../Components/Base/Page';
import { Grid, Typography } from '@mui/material';
import { NotificationTable } from '../Components/Notifications/NotificationTable';
import { useNotificationsQuery } from '../Services/API';

export function Notifications() {
  const endDate: Date = useMemo(() => {
    return new Date(Date.parse(Date()));
  }, []);
  const startDate: Date = useMemo(() => {
    return new Date(new Date().setDate(endDate.getDate() - 7));
  }, [endDate]);
  const { data, isLoading } = useNotificationsQuery({
    count: 20,
    startDate: startDate.toUTCString(),
    endDate: endDate.toUTCString(),
  });

  return (
    <Page
      docTitle='My Notifications'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          My Notifications
        </Typography>
      }
      subheader={
        <Typography variant='body1' color='textSecondary'>
          All notifications sent to your account in the past 7 days
        </Typography>
      }>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <NotificationTable rows={data || []} loading={isLoading} />
        </Grid>
      </Grid>
    </Page>
  );
}
export default Notifications;
