import { Map } from "maplibre-gl";
import { useEffect, useRef, useState } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import { Location, useRadarAnimListQuery } from "../../../../../Services/API";
import { radarTypes } from "../../../../../features/map/mapSlice";
import { Viewport, getBBox } from "../../Util";
import { RadarSpeed } from "../Radar";
import { useInterval } from "../../Util/useInterval";
import { BaronUtil } from "../Util/BaronUtil";
import { RadarImageBundle, RadarUtil } from "../Util/RadarUtil";

export interface PlayImageSourceProps {
  hasLocation?: boolean;
  mapRef: Map;
  opacity: number;
  location: Location | undefined;
  radarType: radarTypes;
  viewport: Viewport;
  speed: RadarSpeed;
  progress: number;
  handleSetProgress: (value: number) => void;
  handleLabelChange: (value: string) => void;
  handleLoadingState: (value: boolean) => void;
}

export default function PlayImageSource(props: PlayImageSourceProps) {
  const { hasLocation, mapRef, opacity, location, radarType, viewport, speed, progress, handleSetProgress, handleLabelChange, handleLoadingState } = props;
  const [coordinates, setCoordinates] = useState<number[][]>(RadarUtil.getCoordinates(mapRef));
  const [radarParams, setRadarParams] = useState(BaronUtil.getRadarParams(radarType, location));

  const { data: radarAnimList, isSuccess, isLoading } = useRadarAnimListQuery(radarParams, { pollingInterval: 180000 });
  const [currentUrl, setCurrentUrl] = useState<string>(RadarUtil.transparentImageUrl);
  const [imageBundles, setImageBundles] = useState<RadarImageBundle[]>([]);
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (radarAnimList) {
      setImagesLoaded(false);
      const urls = BaronUtil.getPaths(viewport, mapRef, radarAnimList)
      RadarUtil.preloadImages(urls).then((imageBundles) => {
        setImageBundles(imageBundles);
        setImagesLoaded(true);
      });
    }
  }, [radarAnimList])

  useEffect(() => {
    if (isLoading || !imagesLoaded) {
      handleLoadingState(true);
    }
    if (imagesLoaded) {
      handleLoadingState(false);
    }
  }, [isLoading, imagesLoaded])

  useEffect(() => {
    const radarParams = BaronUtil.getRadarParams(radarType, location)
    setRadarParams(radarParams);
  }, [radarType, location]);

  useInterval(() => {
    if (isSuccess && radarAnimList && radarAnimList.length > 0 && imagesLoaded) {
      const progressStep = 100 / radarAnimList.length;
      if (progress + progressStep >= 100) {
        handleSetProgress(0);
      }
      else {
        handleSetProgress(progress + progressStep);
      }
    }
  }, speed);

  useEffect(() => {
    if (isSuccess && radarAnimList && imagesLoaded) {
      //Need to debounce this so we don't send requests everytime the viewport moves.
      debounce(() => {
        const bundle = imageBundles[BaronUtil.getCurrentBundle(imageBundles, progress)];
        setCurrentUrl(bundle.base64);
        handleLabelChange(BaronUtil.parseDateFromUrl(bundle.url, radarType));
      }, 100);
    }
    setCoordinates(RadarUtil.getCoordinates(mapRef))
  }, [radarAnimList, isSuccess, viewport, mapRef, progress, imagesLoaded])

  const timerRef = useRef<NodeJS.Timeout>();
  const debounce = (func: any, wait: number) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      timerRef.current = undefined;
      func();
    }, wait);
  }

  return (
    <Source coordinates={coordinates} id='play-image-radar' url={currentUrl} type='image'>
      <Layer beforeId={hasLocation ? 'radii-1' : 'admin_sub'} {...{ ...animStyle, paint: { 'raster-fade-duration': 0, 'raster-opacity': opacity } }} />
    </Source>
  )
}

const animStyle: LayerProps = {
  id: 'play-image-radar',
  type: 'raster',
  minzoom: 0,
  maxzoom: 14,
  paint: {
    'raster-fade-duration': 0,
  }
};


